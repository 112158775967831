import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { for: "name" }
const _hoisted_3 = { class: "field p-mt-3" }
const _hoisted_4 = { for: "email" }
const _hoisted_5 = { class: "field p-mt-3" }
const _hoisted_6 = { for: "password" }
const _hoisted_7 = { class: "p-pl-2 p-ml-2 p-mt-0" }
const _hoisted_8 = { class: "field p-mt-3" }
const _hoisted_9 = { for: "confirm" }
const _hoisted_10 = { class: "field p-mt-3" }
const _hoisted_11 = { for: "codePromo" }
const _hoisted_12 = {
  key: 0,
  class: "field-checkbox p-mt-3"
}
const _hoisted_13 = { for: "binary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FieldErrors = _resolveComponent("FieldErrors")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.$t('signUp.updateUserAccount'),
    visible: _ctx.show,
    "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('update:show', $event))),
    breakpoints: { '960px': '80vw' },
    style: { width: '30vw' },
    onShow: _cache[14] || (_cache[14] = ($event: any) => (_ctx.onShowDialog(_ctx.user))),
    modal: true
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('cancel'),
        icon: "pi pi-times",
        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('update:show', false))),
        class: "p-button-text",
        autofocus: ""
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        loading: _ctx.loading,
        disabled: _ctx.loading,
        onClick: _ctx.submit,
        icon: "pi pi pi-exclamation-triangle",
        class: "p-button-raised p-button-warning",
        "icon-pos": "right",
        label: _ctx.$t('submit')
      }, null, 8, ["loading", "disabled", "onClick", "label"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
        autocomplete: "off",
        class: "p-fluid"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("signUp.name")), 1),
          _createVNode(_component_InputText, {
            id: "name",
            "model-value": _ctx.form.name.$value,
            placeholder: _ctx.$t('signUp.name'),
            class: _normalizeClass({ 'p-invalid': _ctx.form.name.$hasError }),
            onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.name.$value = ($event.target as any).value)),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onKeyEnter('name', ($event.target as any).value)), ["enter"])),
            "aria-describedby": "username2-help"
          }, null, 8, ["model-value", "placeholder", "class"])
        ]),
        _createVNode(_component_FieldErrors, {
          errors: _ctx.form.name.$errors
        }, null, 8, ["errors"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("signUp.email")), 1),
          _createVNode(_component_InputText, {
            id: "email",
            "model-value": _ctx.form.email.$value,
            placeholder: _ctx.$t('signUp.email'),
            class: _normalizeClass({ 'p-invalid': _ctx.form.email.$hasError }),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.email.$value = ($event.target as any).value)),
            onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.onKeyEnter('email', ($event.target as any).value)), ["enter"]))
          }, null, 8, ["model-value", "placeholder", "class"])
        ]),
        _createVNode(_component_FieldErrors, {
          errors: _ctx.form.email.$errors
        }, null, 8, ["errors"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("signUp.password")), 1),
          _createVNode(_component_Password, {
            modelValue: _ctx.form.password.$value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.password.$value) = $event)),
            class: _normalizeClass({ 'p-invalid': _ctx.form.password.$hasError }),
            onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (
            _ctx.onKeyEnter(
              'password',
              ($event.target as any).value || ($event.target as any)._value
            )
          ), ["enter"])),
            placeholder: _ctx.$t('signUp.password')
          }, {
            footer: _withCtx(() => [
              _createVNode(_component_Divider, { style: {"background":"gainsboro","height":"1px"} }),
              _createElementVNode("ul", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('validation.validPassword'), (m, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(m), 1))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "class", "placeholder"])
        ]),
        _createVNode(_component_FieldErrors, {
          errors: _ctx.form.password.$errors
        }, null, 8, ["errors"]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("signUp.confirm")), 1),
          _createVNode(_component_InputText, {
            id: "confirm",
            type: "password",
            modelValue: _ctx.form.confirm.$value,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.confirm.$value) = $event)),
            placeholder: _ctx.$t('signUp.confirm'),
            class: _normalizeClass({ 'p-invalid': _ctx.form.confirm.$hasError }),
            onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.onKeyEnter('confirm', ($event.target as any).value)), ["enter"]))
          }, null, 8, ["modelValue", "placeholder", "class"])
        ]),
        _createVNode(_component_FieldErrors, {
          errors: _ctx.form.confirm.$errors
        }, null, 8, ["errors"]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("signUp.codePromo")), 1),
          _createVNode(_component_InputText, {
            type: "number",
            "model-value": (_ctx.form.instigatorId.$value as any),
            placeholder: _ctx.$t('signUp.codePromo'),
            class: _normalizeClass({ 'p-invalid': _ctx.form.instigatorId.$hasError }),
            onBlur: _cache[8] || (_cache[8] = ($event: any) => (
            _ctx.form.instigatorId.$value = _ctx.getInstigator(
              ($event.target as any).value
            )
          )),
            onKeyup: _cache[9] || (_cache[9] = _withKeys(($event: any) => (
            _ctx.onKeyEnter(
              'instigatorId',
              _ctx.getInstigator(($event.target as any).value)
            )
          ), ["enter"]))
          }, null, 8, ["model-value", "placeholder", "class"])
        ]),
        _createVNode(_component_FieldErrors, {
          errors: _ctx.form.instigatorId.$errors
        }, null, 8, ["errors"]),
        (!_ctx.form.activation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_Checkbox, {
                modelValue: _ctx.form.activation,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.activation) = $event)),
                binary: true,
                "true-value": 1,
                class: "p-mr-3",
                "false-value": 0
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("signIn.activate")), 1)
            ]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}