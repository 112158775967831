import { useQuery, useResult } from "@vue/apollo-composable";
import { gql } from "@apollo/client";
import { reactive, ref } from "vue";
import {
  PaginationInput,
  QueryUsersRevenuesArgs,
  UsersRevenuePagination,
} from "../types";
import { InitialPagination, PAGINATION_META } from "@/graphql/utils/pagination";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useI18n } from "vue-i18n";

type UsersRevenuesData = {
  usersRevenues: UsersRevenuePagination;
};

const USERS_REVENUES = gql`
    query UsersRevenues($input: PaginationInput!) {
        usersRevenues(input: $input) {
            items {
                id
                name
                email
                status
                revenue
                createdAt
                instigatorId
            }
            ${PAGINATION_META}
        }
    }
`;

const FILTERS_ = {
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  email: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  status: {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  revenue: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  instigatorId: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};
export const useUsersRevenues = () => {
  const input = reactive<PaginationInput>({
    keyword: "",
    page: 1,
    limit: 20,
  });
  const filters = ref(FILTERS_);

  const { t } = useI18n();

  const { result, loading } = useQuery<
    UsersRevenuesData,
    QueryUsersRevenuesArgs
  >(USERS_REVENUES, { input });
  const user = useResult(result, InitialPagination, (res) => {
    return {
      ...res.usersRevenues,
      items: res.usersRevenues.items.map((it) => ({
        ...it,
        status: it.status === 1 ? 1 : 0,
      })),
    };
  });
  function clearFilter() {
    Object.assign(filters.value, FILTERS_);
  }
  return {
    input,
    user,
    loading,
    filters,
    clearFilter,
    userStatus: [
      {
        label: t("signIn.validated"),
        value: 1,
      },
      {
        label: t("signIn.inProgress"),
        value: 0,
      },
    ],
  };
};
