import { MutationUpdateByAdminUserArgs, UserRevenue } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { validMail } from "@/graphql/utils/send-mail";
import { useValidation } from "vue3-form-validation";
import { trim } from "@/graphql/utils/utils";

type UserUpdateData = {
  updateByAdminUser: UserRevenue;
};

const MUTATION = gql`
  mutation UpdateByAdminUser($input: UserUpdateByAdminInput!) {
    updateByAdminUser(input: $input) {
      id
      name
      email
      status
      revenue
      instigatorId
    }
  }
`;

export const useUserUpdateByAdmin = (callback: () => void) => {
  function validInstigator(n: number) {
    if (!n) return true;
    return n.toString().length === 6;
  }

  const { validateFields, hasError, form } = useValidation({
    id: 0,
    activation: 0,
    name: {
      $value: "",
      $rules: [(n: string) => !n && t("signUp.validation.name")],
    },
    email: {
      $value: "",
      $rules: [(m: string) => !validMail(m) && t("signUp.validation.email")],
    },
    instigatorId: {
      $value: null,
      $rules: [
        (n: number) => !validInstigator(n) && t("signUp.validation.codePromo"),
      ],
    },
    password: {
      $value: "",
      $rules: [
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
    confirm: {
      $value: "",
      $rules: [
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
  });

  function onShowDialog(user: UserRevenue) {
    form.id = user.id;
    form.activation = user.status;
    form.instigatorId.$value = user.instigatorId;
    form.email.$value = user.email;
    form.name.$value = user.name;
  }

  const toast = useToast();
  const { t } = useI18n();

  const { onDone, mutate, loading, onError } = useMutation<
    UserUpdateData,
    MutationUpdateByAdminUserArgs
  >(MUTATION);
  onDone(({ data, errors }) => {
    if (data?.updateByAdminUser) {
      toast.add({
        severity: "success",
        summary: t("update.title"),
        detail: t(`update.success`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      callback();
    } else if (errors) {
      const errCode = errors[0]?.message?.includes("INSTIGATOR_NOT_FOUND");
      toast.add({
        severity: "error",
        summary: t("update.title"),
        detail: t(errCode ? "signUp.validation.codePromo" : "update.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("update.title"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function submit() {
    validateFields().then((value) => {
      trim(value);
      const { confirm, ...input } = value;
      console.log(input);
      void mutate({ input });
    });
  }
  function onKeyEnter(field: string, value: any) {
    if (typeof value === "string") value = value.trim();
    form[field].$value = value;
    form[field].$validate();
    submit();
  }
  return {
    submit,
    loading,
    validateFields,
    hasError,
    form,
    onKeyEnter,
    getInstigator: (val: string) => Number(val) || null,
    onShowDialog,
  };
};
