
import { PropType, defineComponent } from "vue";
import FieldErrors from "../common/FieldErrors.vue";
import { UserRevenue } from "@/graphql/types";
import { useUserUpdateByAdmin } from "@/graphql/user/user-update-by-admin";

export default defineComponent({
  name: "UserUpdateByAdmin",
  props: {
    user: Object as PropType<UserRevenue>,
    show: Boolean,
  },
  emits: ["update:show"],
  components: {
    FieldErrors,
  },
  setup(_, { emit }) {
    return {
      ...useUserUpdateByAdmin(() => {
        emit("update:show", false);
      }),
    };
  },
});
