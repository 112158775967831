
import { defineComponent, reactive } from "vue";
import { useUsersRevenues } from "@/graphql/user/users-revenues";
import { getImage, numberSeparators } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
import UserUpdateByAdmin from "../../components/account/UserUpdateByAdmin.vue";
import { UserRevenue } from "@/graphql/types";

export default defineComponent({
  name: "AdminUsers",
  components: {
    UserUpdateByAdmin,
  },
  setup() {
    const userToUpdate = reactive({
      show: false,
      user: null,
    });

    function openUpdate(user: UserRevenue) {
      userToUpdate.user = user;
      userToUpdate.show = true;
    }
    return {
      ...useUsersRevenues(),
      openUpdate,
      userToUpdate,
      getImage,
      numberSeparators,
      activeActivity,
    };
  },
});
